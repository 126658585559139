/*
 * App CSS
 * ----------------------------------------------------------------------------
 * This file contains Sass/CSS that should be used throughout the entire app.
 * See https://ionicframework.com/docs/theming/basics for details.
 */

/*BEGIN_SYSTEM_STYLES*/

ionic-selectable-modal ion-toolbar ion-button {
	text-transform: none;
}

.field-caption {
	font-weight: bold;
}

.field-caption::after {
	content: ": ";
}

ion-item .label-stacked {
	margin-bottom: 2px !important;
}

ion-label ~ ion-text.field {
	padding-top: .5rem;
	padding-bottom: .5rem;
}

.validate-error.ion-padding {
	padding-top: .5rem;
	padding-bottom: .5rem;
}

// file-upload {
// overflow: hidden;
// display: block;
// background: #fafafa;
// padding: 20px 66px 20px 20px;
// min-height: 140px;
// outline: 1px dashed #92b0b3;
// outline-offset: 97px;
// position: relative;
// top: unset !important;
//     left: unset !important;
//     margin-left: unset !important;
//     transform: unset !important;
//     text-align: center;
// }
file-upload {
	width: 100%;
	background: transparent !important;
	padding: 0 !important;
	min-height: 0 !important;
	outline-style: none !important;
	outline-width: 0 !important;
	outline-color: invert !important;
	outline-offset: 0 !important;
	position: unset !important;
  width: auto !important;
}
	label.upload-input {
		width: 100%;
	}
//   .button-file ion-icon, .button-gallery ion-icon, .button-camera ion-icon {
//     font-size: 1rem !important;
//     margin-right: 0 !important;
// }
// .button-inner {
//     display: unset !important;
// }
// .button-native {
//   overflow: unset !important;
// }
// .button-native::after {
//   inset: unset;
//   position: unset !important;
//   content: "";
//   opacity: 0;
// }
// 	.upload-input {
//     cursor: pointer;
//     display: unset !important;
//     color: var(--ion-text-color, #000) !important;
//     position: unset !important;
//     margin: auto;
//     transform: unset !important;
//     outline: none;

// 		// &:hover {
// 		// 	color: #ffffff !important;
// 		// }
// 	}
// }

// :root .button-native.button-inner {
//   justify-content: end !important;
// }
// .button-file,
// .button-gallery,
// .button-camera {
// 	ion-icon {
// 		font-size: 1rem !important;
// 		margin-right: .5rem !important;
// 	}
// }

@media (max-width: 576px) {
	.upload-source {
		display: none !important;
	}
}

.file-list {
	width: 100% !important;
}

ion-button ion-spinner {
	margin-left: .5rem;
}

.card-md .text-md-danger p {
	color: var(--ion-color-danger);
}

.left-thumbnail {
	margin-right: .5rem;
}

.right-thumbnail {
	margin-left: .5rem;
}
/*END_SYSTEM_STYLES*/

/*BEGIN_USER_STYLES*/
// [dir="rtl"] .split-pane-side {
//     left: auto;
//     right: 0;
// }
@font-face {
	font-family: "Tajawal";
	src: url("../assets/fonts/Tajawal-Regular.ttf");  // Give relative path to your css file.
  }
:root[mode=ios],
:root[mode=md] {
  --ion-font-family: "Tajawal", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif !important;
  font-family: "Tajawal",-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", "Tajawal",sans-serif !important;
}
:root[mode=ios],
:root[mode=md] {
.filterMenu{
  --width:250px;
  --font-weight: 900;
  }
}
// html.ios {
//   --ion-default-font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", "Tajawal",sans-serif !important;
// }
// html.md {
//   --ion-default-font: "Roboto", "Helvetica Neue", "Tajawal", sans-serif !important;
// }
.v_big_icon{
	font-size: 240px;
}
.m_big_icon{
	font-size: 128px;
}
.big_icon{
  font-size: 64px;
}
.mid_big_icon {
  font-size: 42px;
}
.mid_icon{
  font-size: 32px;
}
.small_icon{
  font-size: 24px;
}
.text-center{
  text-align: center;
}
.mt-0{
  margin-top: 0rem;
}
.mt-1{
  margin-top: 1rem;
}
.mb-0{
  margin-bottom: 0rem !important;
}
.mb-1{
  margin-bottom: 1rem;
}
.mb-2{
  margin-bottom: 2rem;
}
.font-weight-600{
	font-weight: 600;
}
.font-weight-900{
	font-weight: 900;
}
.menu-header-bg{
	text-align: center;
}
.header-logo{
  font-size: 36px;
  padding: 14px;
}
.menu-logo{
  font-size: 120px;
  padding: 20px;
}
.segment-button-disabled {
    opacity: 0.6 !important;
}
.gm-style .gm-style-iw-c {
    padding: 0px !important;
	--ion-font-family: "Tajawal", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif !important;
	font-family: "Tajawal",-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", "Tajawal",sans-serif !important;
}
.gm-style-iw div {
	overflow:hidden!important;
}
.gm-style div[aria-hidden = "true"] {
	font-family: "Tajawal",-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", "Tajawal",sans-serif !important;
    background-color: #0f1dac;
    color:#fff !important;
    padding: 2px 4px;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #317aff;
    font-size: 14px !important;
    // height: 50px;
}

.map-feature {
  background-color: #000;
      color: #fff;
      padding: 6px 4px;
      border: 1px solid #000;
      border-radius: 8px;
  }
  .map-feature-title {
    font-family: "Tajawal",-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", "Tajawal",sans-serif !important;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 12px;
    font-weight: 600;
  }
  .map-feature-text {
    font-family: "Tajawal",-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", "Tajawal",sans-serif !important;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 10px;
    font-weight: 600;
  }
  .map-feature-pin {
    position: relative;
    background: url("../assets/icon/sort_down.svg") no-repeat;
    background-size: 100% 100%;
    fill: #2f2f2f;
    width: 24px;
    height: 24px;
    margin: auto;
    top: -10px;
}
// .gm-style div[aria-hidden = "true"] {
//     position: absolute;
//     width: 0;
//     height: 0;
//     border-left: 5px solid transparent;
//     border-right: 5px solid transparent;
//     border-top: 8px solid #fff; /* Adjust the color and size as needed */
//     top: 100%;
//     left: 50%;
//     transform: translateX(-50%);
// }

// .gm-style div[aria-hidden="true"]{
//   background-color: #ff0cc0 !important;
// }
.map-label-class-0 {
  background-color: #a0a8ff !important;
  border: 1px solid #5893ff;
}
.map-label-class-1 .gm-style div[aria-hidden="true"] {
  background-color: #ff0000 !important;
}
.header-normal{
	background-color: var(--ion-background-color, #fff);
}
.header-transparent{
	background-color: transparent !important;
}
.header-md::after {
  background-image: none;
}
.header-md {
  box-shadow: unset !important;
}
.actionsheet-custom > div > div > div > button > span {
	line-height: 1.2 !important;
}
.custom-ngx-slider .ngx-slider .ngx-slider-pointer {
  width: 24px;
  height: 24px;
  top: -10px;
  bottom: 0;
  background-color: var(--color) !important;
  border-radius: 4px;
  }
.custom-ngx-slider  .ngx-slider .ngx-slider-pointer:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 4px;
    background:  var(--ion-background-color, #fff);
}
.custom-ngx-slider .ngx-slider .ngx-slider-selection {
  z-index: 2;
  background: #6c6c6c40 !important;
  border-radius: 2px;
} 
.custom-ngx-slider .ngx-slider .ngx-slider-pointer .ngx-slider-active:after {
  background-color: #6c6c6c40 !important;
  }
// SCSS for properties_lists(list) page
// Thumbmails
ion-item{
--border-color: transparent !important;
}
.filter-input{
    box-shadow: none !important;
    border: 1px solid #6c6c6c40 !important;
	padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 8px;
    font-weight: 900;
    width: 80% !important;
}
// ::ng-deep{
// 	.ngx-slider .ngx-slider-pointer {
//     width: 24px;
//     height: 24px;
//     top: -10px;
//     bottom: 0;
//     background-color: #fff !important;
//     background-color: var(--ion-color-base) !important;
//     border-radius: 4px;
//   }
//   .ngx-slider .ngx-slider-bar {
//     left: 0;
//     width: 100%;
//     height: 4px;
//     z-index: 1;
//     background: #a59c9c !important;
//     border-radius: 2px;
// }
//   .ngx-slider .ngx-slider-selection {
//     z-index: 2;
// 	background: var(--ion-color-base);
//     color: var(--ion-color-contrast);
//     border-radius: 2px;
// }
// .ngx-slider .ngx-slider-pointer:hover:after {
//     background:#444444 !important;
// }
// }
// We need to use ::ng-deep to overcome view encapsulation
::ng-deep {
    .custom-ngx-slider .ngx-slider .ngx-slider-bar {
      background: #ffe4d1;
      height: 5px;
    }
    .custom-ngx-slider .ngx-slider .ngx-slider-selection {
      background: red;
    }
  
    .custom-ngx-slider .ngx-slider .ngx-slider-pointer {
      width: 24px;
      height: 24px;
      top: -10px;
      bottom: 0;
      background-color: #fff !important;
      background-color: var(--ion-color-base) !important;
      border-radius: 4px;
    }
  
    .custom-ngx-slider .ngx-slider .ngx-slider-pointer:after {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: 8px;
      left: 8px;
      border-radius: 4px;
      background: #fff;
    }
  
    .custom-ngx-slider .ngx-slider .ngx-slider-bubble {
      bottom: 14px;
    }
  
    .custom-ngx-slider .ngx-slider .ngx-slider-limit {
      font-weight: bold;
      color: red;
    }
  
    .custom-ngx-slider .ngx-slider .ngx-slider-tick {
      width: 3px;
      height: 15px;
      margin-left: 4px;
      border-radius: 0;
      background: #d6d6d62e;
      top: -1px;
    }
  
    .custom-ngx-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
      background: rgb(53, 55, 57);
    }
  }
  .alert-wrapper.sc-ion-alert-md{
  min-width: 80%;
    max-width: 80%;
    min-height: 20%;
    max-height: 60%;
  }
  page-properties_lists-view {
  shrinking-segment-header {
    background-color: #363636;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 50%;
      width: 75px;
      height: auto;
      margin-right: 15px;
    }
  }
}
.trans-toolbar{
  --background: transparent !important; position: absolute; top: 35px; width: 100%;
}
.round-button-circle {
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #cfdcec;
  overflow: hidden;
  background: #e8ebee;
  box-shadow: none;
  margin: 0 4px;
  line-height:1.1em;
}
.round-button-circle:hover {
  background:#c2c2c2;
}
.round-button a {
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #cfdcec;
  overflow: hidden;
  background: #e8ebee;
  box-shadow: none;
  margin: unset;
  line-height: 1.1em;
  text-decoration:none;
}
a {
  background-color: transparent;
  color: #6f6f6f;
}
.text_truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}
.custom-modal {
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);

  &::part(backdrop) {
      background: #00000020;
  }

  &::part(handle) {
      // background: var(--ion-color-primary);
      width: 50px;
      height: 4px;
  }

  &::part(content) {
      border-radius: 18px 18px 0 0; // Here the border radius
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}
.custom-modal {
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);

  &::part(backdrop) {
      background: #000;
  }

  &::part(handle) {
      // background: var(--ion-color-primary);
      width: 50px;
      height: 4px;
  }

  &::part(content) {
      border-radius: 0; // Here the border radius
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

// ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
//   --backdrop-opacity: 0;
//   --box-shadow: none;
//   --border-radius: 18px;
//   --height:60%;
// }
// :host(.modal-sheet) {
//   --min-height: 60vh;
//   --max-height: 60vh;
// }

.custom-big-button {
    height: 46px;
    border-radius: 16px !important;
    width: 100%;
}
.clear_segement_underline {
  --color-checked: transparent;
  --indicator-color: none;
  --indicator-height: 0px;
  --indicator-box-shadow: none;
  }
  .short_segement_indicator::part(indicator) {
    width: 50px;
    text-align: center;
  }

  .short_segement_indicator::part(indicator) {
    width: 50px;
    margin: auto;
  }
  .text-nowrap{
    white-space: nowrap;
  }
  .sc-ion-searchbar-md-h {
    --background: var(--ion-background-color, #fff);
    --border-radius: 2px;
    /* --box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
	--box-shadow:none;
    --cancel-button-color: var(--ion-color-step-900, #1a1a1a);
    --clear-button-color: initial;
    --color: var(--ion-color-step-850, #262626);
    --icon-color: var(--ion-color-step-600, #666666);
    // /* padding-left: 8px; */
    // /* padding-right: 8px; */
    // /* padding-top: 8px; */
    // /* padding-bottom: 8px; */
	padding: 2px;
    background: inherit;
    border: none;
    border-radius: 8px;
}
ion-input{
--background: var(--ion-background-color, #fff);
--border-radius: 2px;
/* --box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
--box-shadow: none;
--cancel-button-color: var(--ion-color-step-900, #1a1a1a);
--clear-button-color: initial;
--color: var(--ion-color-step-850, #262626);
--icon-color: var(--ion-color-step-600, #666666);
--padding-top: 15px !important;
--padding-bottom: 15px !important;
--padding-start: 10px !important;
--padding-end: 10px !important;
  background: inherit;
  border: 1px solid rgba(214, 214, 214, 0.431372549);
  // border-radius: var(--border-radius) !important;
  border-radius: 6px !important;
  margin: 10px 0;
}

  .action-sheet-group.sc-ion-action-sheet-md:first-child {
    padding-top: 0;
    border-radius: 16px 16px 0 0;
}
ion-chip{
  border-radius: 6px !important;
}

.alert-info {
  border: 1px solid rgba(0, 0, 0, 0.1215686275);
  border-radius: 8px;
  background: rgba(150, 151, 167, 0.0588235294);
  text-align: justify;
  font-weight: 600;
  margin: 4px;
}

.img-radius-8::part(image) {
  border-radius: 8px;
}

.fab-button-rounded-square {
  --border-radius: 8px !important;
  --background: #ccc !important;
}

.font-color-b3b3b3{
  color: #b3b3b3;
}
  // .short_segement_indicator::part(indicator-background) {
  //   background: transparent;
  // }

  .header-clear {
    .header-md::after {
      background-image: none !important;
    }
  }

  .custom-toast {
	  margin-top: 60px !important;
	}
  .ion-card-clean{
    box-shadow: none;
  }
ion-card-content{
  box-shadow: none !important;
	border: 1px solid transparent;
  }
  df-messenger {
    --df-messenger-bot-message:  var(--ion-background-color, #f5f5f5);	/*Bubble background color for agent messages.*/
    --df-messenger-button-titlebar-color:  transparent;	/*Color for the floating button and the titlebar of the chat dialog.*/
    --df-messenger-button-titlebar-font-color:  transparent;	/*Font color for the title in the titlebar.*/
    --df-messenger-chat-background-color:  var(--ion-background-color, #f5f5f5);	/*Color for the chat dialog background.*/
    --df-messenger-font-color:  var(--ion-text-color, #000);	/*Font color for messages.*/
    --df-messenger-input-box-color:  var(--ion-background-color, #f5f5f5);	/*Background color for the text input box.*/
    --df-messenger-input-font-color: var(--ion-text-color, #000);	/*Font color for the text input box.*/
    --df-messenger-input-placeholder-font-color:  var(--ion-text-color, #000);	/*Font color for placeholder text in text input box.*/
    --df-messenger-minimized-chat-close-icon-color:  transparent	/*Color of the close icon in the closed chat view.*/
    --df-messenger-send-icon:  #8e8e8e2e;	/*Color of the send icon in the text input box.*/
    --df-messenger-user-messag:  #d6d6d62e;	/*Bubble background color for user messages.*/
    --df-messenger-chip-border-color:#d6d6d62e;
    --df-messenger-align-self: flex-start !important;
  }
    ::ng-deep #messageList .message.user-message {
    align-self: flex-start !important;
    background-color: #eeeeee;
    background-color: var(--df-messenger-user-message);
    margin-left: 75px;
    }
    
    ::ng-deep #messageList .message.bot-message {
    align-self: flex-end !important;
    background-color: #E1F5FE;
    background-color: var(--df-messenger-bot-message);
    margin-right: 75px;
    line-height: 1.4;
    }
    
    ::ng-deep .input-container input {
    background-color: white;
    background-color: var(--df-messenger-input-box-color);
    border: none;
    border-radius: 0 0 4px 4px;
    color: rgba(0, 0, 0, 0.87);
    color: var(--df-messenger-input-font-color);
    font-size: 14px;
    padding-right: 15px;
    width: 100%;
    }
  
.custom-card {
	background-color: #f1f1f126;
	border: 2px solid #cccccc12;
	border-radius: 10px;
	padding: 2px;
	text-align: center;
  }
  
  
.custom-list-style{
	width: 100% !important; 
	border:1px solid #cccccc47;
	border-radius: 8px; 
	padding: 0;
}


h1, h2, h3, h4, h5, h6, h7 {
	margin-top: 4px;
	margin-bottom: 8px;
	font-weight: 900;
	line-height: 1.2;
  }
  
  .b-0 {
    border: unset !important;
  }
  .m-0 {
    margin: 0;
  }
  
  .m-1 {
    margin: 0.25rem;
  }
  
  .m-2 {
    margin: 0.5rem;
  }
  
  .m-3 {
    margin: 0.75rem;
  }
  
  .m-4 {
    margin: 1rem;
  }
  
  .m-5 {
    margin: 1.25rem;
  }
  
  /* Margin classes for specific sides */
  .ml-0 {
    margin-left: 0;
  }
  
  .ml-1 {
    margin-left: 0.25rem;
  }
  
  .ml-2 {
    margin-left: 0.5rem;
  }
  
  .ml-3 {
    margin-left: 0.75rem;
  }
  
  .ml-4 {
    margin-left: 1rem;
  }
  
  .ml-5 {
    margin-left: 1.25rem;
  }
  
  .mr-0 {
    margin-right: 0;
  }
  
  .mr-1 {
    margin-right: 0.25rem;
  }
  
  .mr-2 {
    margin-right: 0.5rem;
  }
  
  .mr-3 {
    margin-right: 0.75rem;
  }
  
  .mr-4 {
    margin-right: 1rem;
  }
  
  .mr-5 {
    margin-right: 1.25rem;
  }
  
  .mt-0 {
    margin-top: 0;
  }
  
  .mt-1 {
    margin-top: 0.25rem;
  }
  
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  .mt-3 {
    margin-top: 0.75rem;
  }
  
  .mt-4 {
    margin-top: 1rem;
  }
  
  .mt-5 {
    margin-top: 1.25rem;
  }
  
  .mb-0 {
    margin-bottom: 0;
  }
  
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .mb-5 {
    margin-bottom: 1.25rem;
  }
  .p-0 {
    padding: 0;
  }
  
  .p-1 {
    padding: 0.25rem;
  }
  
  .p-2 {
    padding: 0.5rem;
  }
  
  .p-3 {
    padding: 0.75rem;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .p-5 {
    padding: 1.25rem;
  }
  
  .pl-0 {
    padding-left: 0;
  }
  
  .pl-1 {
    padding-left: 0.25rem;
  }
  
  .pl-2 {
    padding-left: 0.5rem;
  }
  
  .pl-3 {
    padding-left: 0.75rem;
  }
  
  .pl-4 {
    padding-left: 1rem;
  }
  
  .pl-5 {
    padding-left: 1.25rem;
  }
  
  .pr-0 {
    padding-right: 0;
  }
  
  .pr-1 {
    padding-right: 0.25rem;
  }
  
  .pr-2 {
    padding-right: 0.5rem;
  }
  
  .pr-3 {
    padding-right: 0.75rem;
  }
  
  .pr-4 {
    padding-right: 1rem;
  }
  
  .pr-5 {
    padding-right: 1.25rem;
  }
  
  .pt-0 {
    padding-top: 0;
  }
  
  .pt-1 {
    padding-top: 0.25rem;
  }
  
  .pt-2 {
    padding-top: 0.5rem;
  }
  
  .pt-3 {
    padding-top: 0.75rem;
  }
  
  .pt-4 {
    padding-top: 1rem;
  }
  
  .pt-5 {
    padding-top: 1.25rem;
  }
  
  .pb-0 {
    padding-bottom: 0;
  }
  
  .pb-1 {
    padding-bottom: 0.25rem;
  }
  
  .pb-2 {
    padding-bottom: 0.5rem;
  }
  
  .pb-3 {
    padding-bottom: 0.75rem;
  }
  
  .pb-4 {
    padding-bottom: 1rem;
  }
  
  .pb-5 {
    padding-bottom: 1.25rem;
  }
  
  .w-100{
    width: 100%;;
  }

  .global-background{
    background: var(--ion-background-color, #000) !important;
  }

  .global-text-color{
    color: var(--ion-text-color, #000) !important;
  }
.h-40px{
  height: 40px;
}
.h-100{
  height: 100%;
}
.m-auto {
  margin: auto;
}
.text-end {
text-align: end;
}
// Screen size hidden
.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}
  // :root ion-loading {
  //   --background: #e3edff;
  //   --spinner-color: #1c6dff;
  //   display: none;
  
  //   color: #1c6dff;
  //   --z-index: 0;
  // }

  .button-x-small{
    width: 40px;
    height: 40px;
  }
  
  h6 {
		font-size: 14px;
	  }
	  h7 {
		font-size: 12px;
	  }
    .img-top-right-corner-round::part(image) {
      border-radius: 0 8px 8px 0;
    }
    .view-thumbnail-main {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: inherit;
      padding: 0 !important;
    }
    
    .view-thumbnail-full {
      display: block;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: inherit;
      padding: 0 !important;
    }
    .header-full-shadow {
    -moz-box-shadow: inset 0px 150px 20px -20px #00000059;
    -webkit-box-shadow: inset 0px 150px 20px -20px #00000059;
    box-shadow: inset 0px 150px 20px -20px #00000059;
    }
    
    .input-icon-button-start {
      position: absolute;
      right: 20px;
      font-size: 24px;
      cursor: pointer;
    }
    
    .input-icon-button-end {
      position: absolute;
      left: 28px;
      font-size: 24px;
      cursor: pointer;
    }
    
    .input-icon-button-special {
      padding: 0 12px;
      font-size: 26px;
      cursor: pointer;
    }

    .input-inline-label-end {
      position: absolute;
      left: 28px;
      font-size: 18px;
      cursor: pointer;
      top: 50%;
      font-weight: 600;
      padding: 0 0 10px 0;
  }
 ::ng-deep #date-button.ion-activatable {
    border-radius: 8px;
    padding: 16px 10px;
    margin: 0px 2px;
    position: relative;
    transition: color 150ms ease-in-out 0s;
    border: none;
    background: var(--ion-color-step-300, #edeef0);
    color: var(--ion-text-color, #000);
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    appearance: none;
    overflow: hidden;
    width: 100%;
    font-weight: 600;
  }
    ::root :host(.button-solid) {
      --background: var(--ion-background-color, #f5f5f5);
      --color: var(--ion-color-primary-contrast, #fff);
      --height: 50px !important;
    }
    .menu-size {
      --side-min-width: 220px !important;
      --side-max-width: 20% !important;
  }
  @media only screen and (min-width: 768px) and (min-height: 600px){
    ion-modal {
        --width: 100% !important;
        --height: 100% !important;
    }
    .ion-datetime-button-overlay {
      --width: 300px !important;
      --height: auto !important;
  }
  .alert-wrapper.sc-ion-alert-md{
    min-width: 30%;
    max-width: 30%;
    min-height: 20%;
    max-height: 50%;
  }
  :host(.menu-button-hidden) {
    --display: unset !important;
}
.sc-ion-action-sheet-md-h {
  --max-width: 100% !important;
}


.custom-modal-backdrop-clear {
  --backdrop-opacity: 1;
  backdrop-filter: blur(1px);
  align-items: end;
    justify-content: end;

  &::part(backdrop) {
      background: #00000021;
  }

  &::part(handle) {
      // background: var(--ion-color-primary);
      width: 50px;
      height: 4px;
  }

  &::part(content) {
      border-radius: 0 0; // Here the border radius
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
      opacity: 0.8;
      --width: 28% !important;
  }
}

.custom-modal-fixed {
  align-items: end;
  justify-content: end;

  &::part(handle) {
      // background: var(--ion-color-primary);
      width: 50px;
      height: 4px;
  }

  &::part(content) {
      border-radius: 0 0; // Here the border radius
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
      opacity: 0.8;
      --width: 28% !important;
  }
}
  }
.action-sheet-title.sc-ion-action-sheet-md {
  font-size: 16px !important;
  color: var(--ion-text-title-color, #8346aa) !important;
  font-weight: 600 !important;

}
.action-sheet-icon.sc-ion-action-sheet-md {
  font-size: 32px !important;
  color: var(--ion-text-title-color, #8346aa) !important;
  font-weight: 600 !important;

}
    .action-sheet-button {
      font-size: 16px !important;
      color: var(--ion-text-title-color, #8346aa) !important;
      font-weight: 600 !important;
    }
    .selected-label{
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
      white-space: nowrap;
      margin-right: 4px;
        margin-left: 4px;
    }
    .selected-clear-button {
      background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 16px;
        color: var(--ion-text-color, #000);
        padding: 0;
        margin: 0;
        z-index: 9999;
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
      }

    .searchbar-input.sc-ion-searchbar-md {
      padding-left: 55px;
      padding-right: 55px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      background-position: left 8px center;
      height: auto;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      border: 1px solid rgba(214, 214, 214, 0.431372549);
      border-radius: 8px;
      // margin: 10px 0;
  }
  .searchbar-search-icon.sc-ion-searchbar-md {
    left: 16px;
    top: 15px;
    width: 21px;
    height: 21px;
}
ion-button {
  border-radius: 6px !important;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  display: block;
  position: relative;
  width: 100%;
  // height: 100%;
  transition: var(--transition);
  border-width: unset;
  border-style: unset;
  border-color: unset;
  outline: none;
  background: none;
  line-height: 1;
  box-shadow: none;
  contain: layout style;
  cursor: pointer;
  opacity: var(--opacity);
  overflow: var(--overflow);
  z-index: 0;
  box-sizing: border-box;
  appearance: none;
  height: 4.5rem !important;
}
.title-default {
  color: var(--ion-text-title-color, #8346aa);
  font-weight: 900;

}
.strong-title {
  color: var(--ion-text-title-color, #8346aa);
  font-weight: 900;
}
.strong-title-red {
  color: var(--ion-text-title-color, #aa466b);
  font-weight: 900;
}
.font-color-step-50{
  color: var(--ion-color-step-50,#000000) !important;

}
.MapShownClass {
  color: var(--ion-color-step-50,#000000);
  background: #00000045;
}
.ListShownClass {
  color: var(--ion-text-color,#000000);
}
.menu-items-text {
  font-size: 14px;
  font-weight: 900;
}
.menu-items-icon {
  font-size: 24px;
  font-weight: 400;
  color: var(--ion-text-color,#000000) !important;
}

.menu-small-button-h {
  // width: 38px;
  height: 38px;
  font-size: 9px;
  font-weight: 400;
  color: #000000 !important;
  background-color: #ffffff;
  border: 1px solid var(--ion-color-step-100, #6c358e);
  // padding-inline: unset !important;
}

.menu-small-button {
  width: 38px;
  height: 38px;
  font-size: 9px;
  font-weight: 400;
  color: #ffffff !important;
  background-color: var(--ion-background-color, #8346aa);
  border: 1px solid var(--ion-color-step-100, #6c358e);
  padding-inline: unset !important;
}
.menu-small-button-icon {
  font-size: 18px;
  margin: auto;
}
.menu-big-button {
  width: 80px;
  height: 80px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff !important;
  background-color: var(--ion-background-color, #8346aa);
  border: 1px solid var(--ion-color-step-100, #6c358e);
}
.ion-text-vertical {
  display: flex; 
  flex-direction: column; 
  align-items: center;
}
.menu-medium-button {
  width: 60px;
  height: 60px;
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-text-color,#000000) !important;
  background-color: var(--ion-background-color, #ffffff);
  border: 1px solid var(--ion-color-step-100, #6c358e);
  padding-inline: 2px;
}
.menu-big-button ion-icon {
  font-size: 24px;
}
.footer-clean .footer-md::before {
  left: 0;
  top: -2px;
  bottom: auto;
  background-position: left 0 top 0;
  position: absolute;
  width: 100%;
  height: 2px;
  background-image: '' !important;
  background-repeat: repeat-x;
  content: "";
}
.themes-border {
  border: 1px solid var(--ion-color-step-100, #6c358e);
}
.list-row {
  border: 1px solid var(--ion-color-step-100, #e9e9e9);
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
}
.list-col {
  padding-top: var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 2px));
  padding-bottom: var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 2px));
}
::ng-deep {
.list-chip {
  padding-inline: 6px;
  margin-inline: 2px;
  height: 28px;
}
}
.view-row {
  margin: 10px;;
}
// .countdown {
//   font-size: 12px;
//   color: #fff;
//   padding: 2px;
//   margin: 2px;
//   text-align: center;
//   background-color: #585a5e;
//   border-radius: 2px;
// }

.list-features {
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: inset 0px 0px 0 1px rgba(0, 0, 0, 0.168627451);
  padding: 2px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 2px;
  margin-bottom: 4px;
  width: 95%;
}
.bg-light-milky {
  background-color: #ffc7c7 !important;
}
.bg-light-purbal {
  background-color: #ffc7f5 !important;
}
.bg-deep-light-green {
  background-color: #c7ffcf !important;
}
.bg-light-sky-blue {
  background-color: #e3e8fb !important;
}
.bg-light-violate {
  background-color: #f3c3c3 !important;
}
.bg-light-blue {
  background-color: #c5c3f3 !important;
}
.bg-light-orange {
  background-color: #f4b2b2 !important;
}
.bg-light-yallow {
  background-color: #ffffc7 !important;
}
.bg-very-light-green {
  background-color: #c7fbff !important;
}
.bg-light-green {
  background-color: #d1ffc7 !important;
}
.list-counter {
  position: absolute; 
  bottom:0; 
  text-align: center;
  left: 50%; 
  transform: translateX(-50%);
}
.card-counter {
  position: absolute; 
  bottom: 4%; 
  text-align: center; 
  left: 50%; 
  transform: translateX(-50%);
}
.slider-counter {
  position: absolute; 
  top: 0; 
  text-align: center; 
  left: 50%; 
  transform: translateX(-50%);
}
.list-card-content {
  padding-top: var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 0px));
  padding-bottom: var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 0px));
  padding-left: 4px;
  padding-right: 4px;

}
.item-native-unset {
  padding-left: unset !important;
  padding-right: unset !important;
  padding-inline: unset !important;
}
.position-relative {
  position: relative;
}

.top-right-badge{
	position: absolute;
    top: 2%;
    right: 0px;
    background-color: #db0000bf;
    max-width: 120px;
    min-width: 100px;
    padding: 4px 4px;
    border-radius: 4px 0 0 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 900;
    text-align: center;
}
.top-left-badge{
	position: absolute;
    top: 2%;
    left: 0px;
    background-color: #db0000bf;
    max-width: 120px;
    min-width: 100px;
    padding: 4px 4px;
    border-radius: 0 4px 4px 0;
    color: #fff;
    font-size: 12px;
    font-weight: 900;
    text-align: center;
}
.item-clean {
  padding-left: 0 !important;
    padding-right: 0 !important;
}
.filter-clear-button {
	position: absolute;
	left: 4px;
	top: 50%;
	transform: translateY(-50%);
	background-color: transparent; //var(--ion-background-color, #fff);
	border: none;
	cursor: pointer;
	font-size: 24px;
	color: var(--ion-color-step-50,#000000) !important;
	padding: 8px;
	margin: 0;
	z-index: 9999;
  }
  .sc-ion-textarea-md-h {
    display: block;
    position: relative;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    background: var(--background);
    color: var(--color);
    font-family: var(--ion-font-family, inherit);
    white-space: pre-wrap;
    z-index: 2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid rgba(214, 214, 214, 0.431372549);
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgba(214, 214, 214, 0.43);
    border-right-color: rgba(214, 214, 214, 0.43);
    border-bottom-color: rgba(214, 214, 214, 0.43);
    border-left-color: rgba(214, 214, 214, 0.43);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 6px !important;
    padding: 5px 10px;
}
.chip-img {
  margin: auto;
  padding-left: 0.8rem;
  max-width: 34px;
  height: 20px;
}
.alert-wrapper {
  text-align: justify;
}
.alert-button-group.sc-ion-alert-md {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}
.alert-button-group.sc-ion-alert-md button {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.alert-button.sc-ion-alert-md {
  color: var(--ion-color-primary-contrast, #fff) !important;
  background: var(--ion-color-primary, #3880ff);
  margin: auto;
}
.alert-button-inner.sc-ion-alert-md {
  -ms-flex-pack: end;
  justify-content: center;
}
.alert-title.sc-ion-alert-md {
  color: var(--ion-color-primary, #3880ff);
  font-weight: 600;
  margin-bottom: 20px;
}
.alert-message.sc-ion-alert-md {
  max-height: 100%;
}
.alert-message.sc-ion-alert-md {
  font-size: 14px !important;
}
:host(.split-pane-visible) ::slotted(.split-pane-side) {
  --border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  --side-min-width: 220px !important;
  --side-max-width: 20% !important;
}
::ng-deep {
ion-content {
  // --offset-bottom: auto !important;
  // --overflow: auto;
  // background: var(--ion-toolbar-background, var(--ion-background-color, 
  //              #000000)) !important;
  // overflow: auto;

&::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

&::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);
}

&::-webkit-scrollbar-track:hover {
  background: #35d703;
}

&::-webkit-scrollbar-thumb {
  background: rgb(75, 75, 75);
}

&::-webkit-scrollbar-thumb:hover {
   background: #94173a
}

.inner-scroll {
   scrollbar-width: thin;
}
}
}
::ng-deep{
div.chat-wrapper[opened="true"] {
  width: 100% !important;
  height: 100% !important;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale(1, 1);
  transition: transform 0.2s ease, opacity 0.2s ease-in;
}
}
/* src/global.scss */

ion-content {
  --ion-content-scrollbar: all;
}

@media(pointer: fine) {
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888
  }
  .inner-scroll {
    scrollbar-width: thin
  }
}
.overflow-hidden {
  --overflow: hidden;
}

.modal-child {
  padding: 0.5rem !important;
  background-color: var(--ion-background-color, #fff) !important;
  width: 100%;
}

.nafaz-input-container {
  text-align: center;
  margin:auto;
}

.nafaz-input-container input {
  width: 80px;
  height: 54px;
  text-align: center;
}
.status-bar {
  --indicator-color: var(--ion-color-step-350, var(--ion-background-color, #8346aa));
  --padding-end: 8px !important;
  --padding-start: 8px !important;
  font-size: 14px;
  font-weight: 900;
}

.MapShownClass {
  color: var(--ion-color-step-50,#fff);
  background: #00000045;
}
.ListShownClass {
  color: var(--ion-text-color,#000);
}
@media (max-width: 767px) {
  .header-shadow {
    box-shadow: inset 0px 80px 25px -20px #0000007d;
    --background: rgba(255, 255, 255, 0);
    padding: 4px 10px 10px 10px;
    position: absolute;
    top: 0px;
    height: 90px;
  }
}
  @media (min-width: 768px) {
    .header-shadow {
      box-shadow: inset 0px 80px 25px -20px #0000007d;
      --background: rgba(255, 255, 255, 0);
      padding: 4px 10px 10px 10px;
      position: absolute;
      top: 0px;
      height: 90px;
    }
  .MapShownClass {
    color: var(--ion-color-step-50,#fff);
    background: #00000045;
    height: 50px;
  }
  .ListShownClass {
    color: var(--ion-text-color,#000);
    height: 50px;
  }
  .status-bar {
    font-size: 18px;
  }
.action-sheet-wrapper.sc-ion-action-sheet-md {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--ion-safe-area-top, 0);
  margin-bottom: 0;
}

.action-sheet-container.sc-ion-action-sheet-md {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-pack: end;
  /* justify-content: flex-end; */
  height: 100%;
  max-height: 100%;
  align-items: center;
  justify-content: center;
}
.action-sheet-group.sc-ion-action-sheet-md:first-child {
  padding-top: 0;
  border-radius: 16px 16px 0 0 !important;
  width: 40% !important;
}
.action-sheet-group.sc-ion-action-sheet-md:last-child {
  padding-bottom: var(--ion-safe-area-bottom);
  border-radius: 0 0 16px 16px !important;
  width: 40%;
}
.centered-modal {
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);

  &::part(backdrop) {
      background: #00000020;
  }

  &::part(handle) {
      // background: var(--ion-color-primary);
      width: 50px;
      height: 4px;
  }

  &::part(content) {
    border-radius: 18px;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.32);
    min-width: 25%;
    max-width: 35%;
    margin: 80px 0;
    height: 90%;
  }
}
}

ion-loading.global-loader {
  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading-wrapper {
    box-shadow: none !important;
    background: url("../assets/img/icon_cornered.png") no-repeat;
    background-size: 100% 100%;
    --height: 64px;
    --max-height: 64px;
    --max-width:64px;
    --min-height:64px;
    --min-width:64px;
    --width:64px;
  }
}

.otpInputs {
  display: flex;
  text-align: center;
  margin: 0 50px;
}

.text-justify {
  text-align: justify;
}

.files-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  left: unset !important;
  top: unset !important;
  overflow: hidden;
}

.list-visible .upload-input {
  top: 0px;
  left: 100%;
  margin-left: unset !important;
  transform: translate(-100%);
  text-align: center;
}


.icons-info-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.d-flex {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
}
.d-flex-column {
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
}
.justify-content-start {
  justify-content:start;
  align-items: stretch;
  align-content: stretch;

}
.justify-content-space-between {
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}
.cursor-pointer {
  cursor:pointer;
}

	  
::ng-deep {
	.icons-info-group-items {
	  display: inline-flex;
	  flex-direction: row;
	  flex-wrap: wrap;
	  justify-content: flex-start;
	  align-items: flex-start;
	  align-content: space-between;
	  background-color: #d0d0d07a !important;
	  padding: 0 2px;
	  border-radius: 6px !important;
	  margin-left: unset;
	  margin-right: unset;
	  margin: 0 2px;
	}
	.icons-info-group-items img {
	  width: 24px;
	  margin: 0 4px;
	}
	.l-850 {
	  margin-left: 1000px;
	}
  }


  .text-options {
    border: 1px solid rgba(0, 0, 0, 0.1215686275);
    border-radius: 8px;
    background-color: rgba(150, 151, 167, 0.0588235294);
    padding: 16px 8px;
    text-align: justify;
  }
  .field-options {
	  border: 1px solid #0000001f;
	  border-radius: 8px;
	  background-color: #9697a70f;
	  min-height: 80px;
  }
  .field-options-small {
	  border: 1px solid #0000001f;
	  border-radius: 8px;
	  background-color: #9697a70f;
    color: #000;
    text-align: center;
	  padding: 2px;
  }
  .w-33-percent {
	  width: 32.3%;
  }
  .w-50-percent {
	  width: 49.3%;
  }
  .w-66-percent {
	  width: 66.66666%;
  }
  .field-caption-text {
	font-size: 14px;
	font-weight: 900;
	overflow-wrap: break-word;
	white-space: wrap; /* Prevent text wrapping */
	text-overflow: ellipsis;
	overflow: hidden;
  }
  .field-value-text {
	font-weight: 900;
  }
  .field-value-font-18 {
	font-size: 18px;
  }
  .field-value-font-22 {
	font-size: 22px;
  }
.menu-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.1215686275);
    margin-top: 20px;
    padding-top: 15px;
}
.border-top {
	  border-top: 1px solid #0000001f;
  }
  .border-bottom {
	  border-bottom: 1px solid #0000001f;
  }
//   @media (min-width: 768px) {
// 	  .w-33-percent {
// 		  width: 16%;
// 	  }
// 	  .w-50-percent {
// 		  width: 49.3%;
// 	  }
  
//   }


.rounded-image {
	width: 110px;
	height: 110px;
	--border-radius: 8px;
  }

  ion-icon[name="operations"] {
  mask-size: contain;
  mask-position: center;
  background: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  mask-image: url('../assets/icon/treatment_list.svg');
  content: '';
}
ion-icon[name="countries"] {
mask-size: contain;
mask-position: center;
background: currentColor;
width: 1em;
height: 1em;
display: inline-block;
mask-image: url('../assets/icon/country.svg');
content: '';
}
ion-icon[name="regions"] {
mask-size: contain;
mask-position: center;
background: currentColor;
width: 1em;
height: 1em;
display: inline-block;
mask-image: url('../assets/icon/map.svg');
content: '';
}
ion-icon[name="cities"] {
mask-size: contain;
mask-position: center;
background: currentColor;
width: 1em;
height: 1em;
display: inline-block;
mask-image: url('../assets/icon/citydots.svg');
content: '';
}
ion-icon[name="neiborhoods"] {
mask-size: contain;
mask-position: center;
background: currentColor;
width: 1em;
height: 1em;
display: inline-block;
mask-image: url('../assets/icon/mappinpoint.svg');
content: '';
}
ion-icon[name="zipcodes"] {
mask-size: contain;
mask-position: center;
background: currentColor;
width: 1em;
height: 1em;
display: inline-block;
mask-image: url('../assets/icon/zipcode.svg');
content: '';
}
ion-icon[name="streets"] {
mask-size: contain;
mask-position: center;
background: currentColor;
width: 1em;
height: 1em;
display: inline-block;
mask-image: url('../assets/icon/roaddots.svg');
content: '';
}


.status-container {
  border-radius: 6px !important;
  padding: 4px 8px;
}
.status-card {
color:#000;
font-weight: 900;
text-align: center;
margin: auto;
}

.sold-color {
background-color: #d70000;
}
.available-color {
background-color: #15b600;
}

.cff000045 {
	background-color: #ff000045;
}
.ce4ff0045 {
	background-color: #e4ff0045;
}
.ce9ff0045 {
	background-color: #e9ff0045;
}
.cff00ec45 {
	background-color: #ff00ec45;
}
.cbd00ff45 {
	background-color: #bd00ff45;
}
.c00ff6a45 {
	background-color: #00ff6a45;
}
.c7d46aa {
	background-color: #7d46aa;
}
.caa467f {
	background-color: #aa467f;
}
.overflow-hidden {
  overflow:hidden!important;
}
/* Adjust the style of custom controls */
button.custom-control,
input.custom-control {
  font-size: 24px; /* Adjust the icon size */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  color: #000;
  border: none;
  cursor: pointer;
  position: absolute;
  display: none; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
}

/* Style for active (visible) controls */
button.custom-control.active,
input.custom-control.active {
  opacity: 1;
}

/* Adjust fullscreen button style */
button.custom-control.fullscreen {
  right: 10px; /* Adjust the position */
  bottom: 10px; /* Adjust the position */
}


.start-badge {
  position: absolute;
  top: 70px;
  right: 12px;
}
.start-badge-close {
  position: absolute;
  top: 14px;
  right: 12px;
  font-size: 28px;
}

.end-badge {
  position: absolute;
  top: 70px;
  left: 12px;
}

.end-badge-red {
  background: #eb445ac4;
  padding: 4px 15px;
  color: #fff;
  font-size: small;
  border-radius: 4px;
  text-align: center;
}

.end-badge-green {
  background: #6eeb44c4;
  padding: 4px 15px;
  color: #fff;
  font-size: small;
  border-radius: 4px;
  text-align: center;
}
/*END_USER_STYLES*/